import { html } from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-icon';
import { PageViewElement } from '../../page-view-element.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-welcome.scss';
import { store } from '../../../state/store.js';
import {ONBOARDING_VIEW, navigate} from '../../../actions/navigation';

class DdWelcome extends PageViewElement {
  static get styles() {
    return [
      sharedStyles,
      style
    ];
  }

  render() {
    return html`
      <div id="grid-outer" class="dd-flex-container">
        <div id="grid-middle" class="dd-fit-content">
          <div id="grid-inner">
            <div class="content-container">
              <div class="card">
                <div class="card-body">
                  <p class="card-subheader">Welcome!</p>
                  <h2 class="card-header">Dinner is about to get a whole lot easier</h2>

                  <div class="card-content">
                    <p>
                      We’re excited to <strong>create a menu just for you.</strong>
                      Just tell us a bit about what you eat and where you shop.
                      We'll then take care of the rest.
                    </p>
                    <p>
                      Plus, we’ll use this week’s store sales to help you <strong>save money</strong>.
                    </p>
                  </div>
                </div>

                <div class="card-footer">
                  <mwc-button
                      outlined
                      label="Let’s Get Started"
                      class="nav-button dark-filled"
                      @click="${this.onGetStartedClick_}"
                  >
                    <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
                  </mwc-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  /* eslint class-methods-use-this: "off" */
  onGetStartedClick_() {
    store.dispatch(navigate(ONBOARDING_VIEW));
  }
}

customElements.define('dd-welcome', DdWelcome);
