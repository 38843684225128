import { REHYDRATE } from 'redux-persist/es/constants';
import {NAVIGATION_ACTION, LOGIN_VIEW, ONBOARDING_VIEW, MENU_VIEW, WELCOME_VIEW} from '../actions/navigation';
import { SET_TOKEN } from '../actions/token';

const INITIAL_STATE = {
  view: LOGIN_VIEW,
  destination: '',
};

export function navigation(state = INITIAL_STATE, action) {
  const isRehydratingNoState = !action.payload;
  const isRehydratingInitialState =
    action.payload &&
    action.payload.navigation &&
    (action.payload.navigation.view === LOGIN_VIEW ||
      action.payload.navigation.view === INITIAL_STATE.view ||
        action.payload.navigation.view === ONBOARDING_VIEW ||
        action.payload.navigation.view === WELCOME_VIEW);
  const route = state.auth?.token ? MENU_VIEW : state.view;

  switch (action.type) {
    case SET_TOKEN:
      if (state.view === LOGIN_VIEW && action.payload.authToken) {
        return {
          ...state,
          view: MENU_VIEW,
        };
      }
      return state;
    case NAVIGATION_ACTION:
      console.log(
        'NAVIGATION_ACTION',
        action.payload.view,
        action.payload.destination,
        action.payload.title,
      );
      return {
        ...state,
        view: action.payload.view,
        destination: action.payload.destination,
        title: action.payload.title,
      };
    case REHYDRATE:
      return {
        ...state,
        view:
          isRehydratingNoState || isRehydratingInitialState
            ? route
            : action.payload.navigation.view,
        destination:
          isRehydratingNoState || isRehydratingInitialState
            ? state.destination
            : action.payload.navigation.destination,
        title:
          isRehydratingNoState || isRehydratingInitialState
            ? state.title
            : action.payload.navigation.title,
      };
    default:
      return state;
  }
}
